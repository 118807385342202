import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import authFirebaseApp from '../functions/features/auth-firebase-app';
import Header from '../header/header'
import VideoInDialog from './video-in-dialog';
import './display-gallery-page.css'
import { analyticsFunction, updateEndTime, updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import analyticsFirebase from '../functions/features/analytics-firebase-app';
import ChatPanelCountry from '../chatpanel/ChatPanelCountry';

const expoName = 'mela-launch';
const roomName = 'display'
const sceneName = 'display'
const displayGalleryIframeSrc = 'https://angles-expo.firebaseapp.com/dekho/60a5560b21adc70012f8ec35/2/novr/none'

export default class DisplayPage extends Component {


    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false
        })
        // this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user display',user)
        //       // ...
        //     } 
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //       // User is signed out
        //       // ...
        //     }
        //   });
        if( localStorage.getItem('userRole')==='user' && localStorage.getItem('uid') !== ''){
            console.warn('welcome')
        } else{
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'product-launch')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('displayGalleryIframe');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        // this.updateCollection();
        updateLastSceneEndTime(roomName);
    }

    // updateLastSceneEndTime = () => {
    //     console.warn('update last scene end time')
    //     const db = analyticsFirebase.firestore();
    //     db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
    //         endTime: new Date().getTime(),
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ",  localStorage.getItem('analyticsDocRef'));
    //             // currentDoc = docRef;
    //              analyticsFunction(roomName)
    //             setInterval(() => {
    //                updateEndTime();
    //             }, 15000);
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }



    // projectButtonClick = (e) => {
    //     console.log('called on lobby arrow', e.data);
    //     if (e.data.isImageClicked) {

    //         if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
    //            window.location.href='/entrance'
    //         }
    //     }
    // }




    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }

    projectButtonClick = (e) => {
        console.log('display gallery click', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey.includes('expo.virtualpropexpo2020.com/lobby')) {
                window.location.href = '/entrance'
            } 
            else if (e.data.mslKey.includes("expo.virtualpropexpo2020.com/face-tryon")) {
                window.open('https://noor.melzo.com/face/?p=604f284a3a229e001113dd2c', "_blank")
            } 
            else if (e.data.mslKey.includes("expo.virtualpropexpo2020.com/hand-tryon")) {
                window.open('https://noor.melzo.com/hand/?p=604f17a73a229e001113db1f', "_blank")
            } 
            else if (e.data.mslKey.includes("expo.virtualpropexpo2020.com/sales")) {
                window.location.href = '/live-chat';
            } 
        }

        if (e.data.isLinkClicked) {
            if (e.data.url.includes('https://wa.me') || e.data.url.includes(
                'https://api.whatsapp.com')) {
                const form = document.createElement("form");
                form.setAttribute('method', "post");
                form.setAttribute('target', '_blank');
                form.setAttribute('action', e.data.url);
                document.body.append(form);
                form.submit()
            } else {
                let myLink = e.data.url
                if (e.data.url) {
                    if (myLink.includes('youtube.com') || myLink.includes('brightcove')) {

                        // myLink.replace('?usp=sharing', '');
                        // const form = document.createElement("form");
                        // form.setAttribute('method', "get");
                        // form.setAttribute('target', '_blank');
                        // form.setAttribute('action', e.data.url);
                        // document.body.append(form);
                        // form.submit()
                        // document.getElementById('mainVideo').setAttribute('src', 'https://www.youtube.com/embed/XSSRt_XgP30?controls=0&autoplay=1');
                        // document.getElementById('centerPopup').style.display = 'flex';
                        // document.getElementById('popupOverlay').style.display = 'block';
                        this.setState({
                            videoUrl: e.data.url,
                            isDialogOpen: true,
                        })

                    } else {

                        window.open(e.data.url, "_blank")
                        // if (e.data.target == "_blank") {
                        // const form = document.createElement("form");
                        // form.setAttribute('method', "get");
                        // form.setAttribute('target', '_blank');
                        // form.setAttribute('action', e.data.url);
                        // document.body.append(form);
                        // form.submit()
                        // } else {
                        //     window.location.href = e.data.url;
                        // }
                    }

                }
            }
        }


    }

    closePopup = () => {
        document.getElementById('mainVideo').setAttribute('src', '');
        document.getElementById('centerPopup').style.display = 'none';
        document.getElementById('popupOverlay').style.display = 'none';
    }

    render() {

        return (
            <div>
                <Header headerType="user" />
                <div className="overlay" id="popupOverlay" style={{ display: 'none' }}></div>
                <div className="center-video-popup" id="centerPopup" style={{ display: 'none' }}>
                    <div className="close-popup" onClick={() => this.closePopup()}>x</div>
                    <iframe id="mainVideo" title="VR Background" width="560" height="315" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <iframe id="displayGalleryIframe" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={displayGalleryIframeSrc}>
                </iframe>
                <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                {/* <ChatPanelCountry roomName="medexCountry1" /> */}
                <Footer />
            </div>
        )
    }
}
