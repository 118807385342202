import React, { Component } from 'react'

import rotateMobile from '../images/rotate-mobile.png';
import Footer from '../footer/footer-page';
import Header from '../header/header';

import webinarButton from '../images/medexIcon/WEBINAR-MED.png'
import logout from '../images/medexIcon/logout.png';
import './sales-page.css';
import { updateJwtAndTenant } from './setup-jitsi';


const salesBgImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/mela-launch-sales-room-bg.jpg';


export default class ModeratorSalesPage extends Component {


    componentDidMount() {
        document.getElementById('meet').style.display = 'block'
        updateJwtAndTenant('melzoMelaLaunch')
    }


    openWebinar = () => {
        window.location.href = "/moderator-webinar";
    }

    logOut() {
        localStorage.clear();
        window.location.href = "/";
    }

    render() {
        return (
            <div>
                <Header headerType="user" />
                <img src={salesBgImg} alt="" className="sales2dImg" />

                <div className="overlaylandscape">
                    <img src={rotateMobile} alt="" className="rotateimg" />
                    <p className="rotatetext">Rotate your device to talk with our sales person</p>
                </div>
                <div
                    className="participantsiconpositionmod"
                    onClick={this.openWebinar}
                >
                    <img
                        src={webinarButton}
                        alt="logout"
                        className=" widthicon marginbottomimg"
                        style={{ display: "flex" }}
                        data-tooltip="tooltip"
                        data-placement="right"
                        title="Participants"
                    />
                    <div className="icontext textshadow">Webinar</div>
                </div>
                <div className="logouticonpositionmod" onClick={() => this.logOut()}>
                    <img
                        src={logout}
                        alt="logout"
                        className=" widthicon marginbottomimg"
                        style={{ display: "flex" }}
                        data-tooltip="tooltip"
                        data-placement="right"
                        title="Logout"
                    />
                    <div className="icontext textshadow">Logout</div>
                </div>
                {/* <Footer /> */}
            </div>
        )
    }
}
